<template>
  <div class="container">
    <div class="box" style="margin-right: 10px; margin-left: -50px; ">
      <h3 style="color: black">{{ title }}</h3>
    </div>
    <div class="box" style="margin-right: -45px">
      <div class="container1">
        <dashboard-long-buttons icon="mdi-plus" />
      </div>
    </div>
  </div>
</template>

<script>
import DashboardLongButtons from "@/components/component/DashboardLongButtons";
export default {
  name: "TitleBar",
  components: { DashboardLongButtons },
  props: ["title"]
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: row;
  wrap: nowrap;
  justify-content: space-between;
  align-items: stretch;
  alignt-content: stretch;
}

.box {
  height: auto;
  width: auto;
  padding: 10%;
}

.container1 {
  display: flex;
  flex-direction: row;
  wrap: nowrap;
  justify-content: space-around;
  align-items: stretch;
  alignt-content: stretch;
}
</style>
